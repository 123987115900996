<template>
  <div>
    <div :style="handleStyle">
      <div>
        <video
          muted
          autoplay
          :src="videoSrc"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      videoWidth: undefined,
    };
  },
  computed: {
    handleStyle() {
      const styleObject = this.videoWidth ? { width: `${ this.videoWidth }px` } : { width: '100%' };
      return styleObject;
    },
  },
  mounted() {
    window.addEventListener( 'resize', this.setWidth );
    this.setWidth();
  },
  beforeDestroy() {
    window.removeEventListener( 'resize', this.setWidth );
  },
  methods: {
    setWidth() {
      const { width, height } = this.$el.getBoundingClientRect();
      const optimalWidth = Math.ceil( ( height / 56.25 ) * 100 );
      if ( width < optimalWidth ) {
        this.videoWidth = optimalWidth;
      } else {
        this.videoWidth = false;
      }
    },
  },
};
</script>
