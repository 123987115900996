<template>
  <transition
    :css="false"
    @enter="enter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script>
import gsap from 'gsap'

const duration = 0.5

export default {
  methods: {
    enter(el, done) {
      gsap.set(
        el,
        {
          height: 'auto',
          overflow: 'hidden',
        },
      )
      gsap.from(
        el,
        duration,
        {
          height: '0',
          ease: 'power4.out',
          onComplete: done,
        },
      )
    },
    leave(el, done) {
      gsap.set(
        el,
        { overflow: 'hidden' },
      )
      gsap.to(
        el,
        duration,
        {
          height: 0,
          ease: 'power4.out',
          onComplete: done,
        },
      )
    },
  },
}
</script>
