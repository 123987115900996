<template>
  <transition name="modal-fade">
    <div
      v-show="!$root.audience"
      class="audience-modal"
    >
      <div class="audience-modal__content">
        <div class="audience-modal__intro">
          <slot />
        </div>
        <div class="audience-modal__selection">
          <div class="audience-modal__button-wrap">
            <span class="audience-modal__button-intro">I'm a</span>
            <button
              class="audience-modal__button"
              @click="setAudience('pbm')"
              v-text="'Pharmacy Benefit Management Company'"
            />
          </div>
          <!-- <div class="audience-modal__button-wrap">
            <span class="audience-modal__button-intro">I'm an</span>
            <button
              class="audience-modal__button"
              @click="setAudience('employer')"
              v-text="'Employer'"
            />
          </div> -->
          <div class="audience-modal__button-wrap">
            <span class="audience-modal__button-intro">I'm a</span>
            <button
              class="audience-modal__button"
              @click="setAudience('pharma')"
              v-text="'Pharmaceutical Company'"
            />
          </div>
          <div class="audience-modal__button-wrap">
            <span class="audience-modal__button-intro">I'm a</span>
            <button
              class="audience-modal__button"
              @click="setAudience('insurance')"
              v-text="'Health Plan or Payer'"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  mounted() {
    this.getAudience()
  },

  methods: {
    getAudience() {
      this.$root.audience = localStorage.getItem( 'lsAudienceType' )
    },
    setAudience(audienceName) {
      localStorage.setItem( 'lsAudienceType', audienceName )
      this.getAudience()
    }
  }
};
</script>

<style>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
