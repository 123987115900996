import Vue from 'vue'

// Sass
import '@/styles/main.scss'

// Post CSS
import 'postcss-object-fit-images'

// Webfontloader
import WebFont from 'webfontloader'

// Pollyfills
import svg4everybody from 'svg4everybody'
import objectFitImages from 'object-fit-images'

// Components
import AppAnimate from '@/js/components/AppAnimate.vue'
import MenuPrimary from '@/js/components/MenuPrimary.vue'
import BaseAudience from '@/js/components/BaseAudience.vue'
import AudienceModal from '@/js/components/AudienceModal.vue'
import BaseAccordion from '@/js/components/BaseAccordion.vue'
import BaseDisclosure from '@/js/components/BaseDisclosure.vue'
import BaseWindowWidth from '@/js/components/BaseWindowWidth.vue'
import BackgroundVideo from '@/js/components/BackgroundVideo.vue'
import BaseAccordionPanel from '@/js/components/BaseAccordionPanel.vue'
import BaseAccordionHeader from '@/js/components/BaseAccordionHeader.vue'
import BaseAccordionHeaderButton from '@/js/components/BaseAccordionHeaderButton.vue'

// Intersection Observer Polyfill
import 'intersection-observer'

Vue.config.productionTip = false

// Webfontloader
WebFont.load({
  google: {
    families: ['Muli:300,400,500,600,700,900']
  },
  // custom: {
  //   families: [
  //     'Promima-Nova:i6,n6,i7,n7',
  //     'Minion-Pro:n5,i5,n6,i6',
  //   ],
  // },
});

svg4everybody()
objectFitImages()

const Components = {
  AppAnimate,
  MenuPrimary,
  BaseAudience,
  AudienceModal,
  BaseAccordion,
  BaseDisclosure,
  BaseWindowWidth,
  BackgroundVideo,
  BaseAccordionPanel,
  BaseAccordionHeader,
  BaseAccordionHeaderButton,
}

Object.keys(Components).forEach(componentName => {
  Vue.component(componentName, Components[componentName])
})

new Vue({
  data() {
    return {
      audience: true
    }
  },
}).$mount('#app')
