<template>
  <component :is="tag">
    <slot />
  </component>
</template>

<script>
import gsap from 'gsap';

export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    animationName: {
      type: String,
      default: 'fadeIn',
    },
    replay: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      intersectionObserver: undefined,
      isAnimating: false,
    };
  },

  mounted() {
    this[ `${ this.animationName }` ]( false );

    this.intersectionObserver = new IntersectionObserver(
      ( [ entry ] ) => this.handleCallback( entry ),
      { threshold: 0.15 },
    );
    this.intersectionObserver.observe( this.$el );
  },

  beforeDestroy() {
    this.intersectionObserver.disconnect();
  },

  methods: {
    handleCallback( { isIntersecting } ) {
      if (this.isAnimating) {
        return;
      }
      this[ `${ this.animationName }` ]( isIntersecting );
      if (isIntersecting) {
        this.isAnimating = true;
      }
    },
    fadeIn() {
      gsap.fromTo(
        this.$el,
        {
          alpha: 0,
        },
        {
          alpha: 1,
          duration: 0.9,
          ease: 'power2.out',
          onComplete: this.maybeDisconnect,
        },
      );
    },
    intro( bool ) {
      const heading = this.$el.querySelector( 'h2' );
      const copy = this.$el.querySelector( '.intro__copy' );
      const columns = this.$el.querySelectorAll( '.intro__column' );

      if (bool) {
        gsap.fromTo(
          [heading, copy, columns],
          {
            alpha: 0,
            y: 50,
          },
          {
            alpha: 1,
            y: 0,
            duration: 0.6,
            ease: 'power2.out',
            delay: 0.5,
            stagger: 0.3,
            onComplete: this.maybeDisconnect,
          }
        );
      } else {
        gsap.set( [heading, copy, columns], { opacity: 0 } );
      }
    },
    checkGroup( bool ) {
      const heading = this.$el.querySelector( 'h2' );
      const copy = this.$el.querySelector( '.check-group__copy-wrap' );
      const items = this.$el.querySelectorAll( '.check-group__item' );

      if (bool) {
        gsap.fromTo(
          heading,
          {
            alpha: 0,
            x: -80,
          },
          {
            alpha: 1,
            x: 0,
            duration: 0.6,
            ease: 'power2.out',
            delay: 0.5,
          }
        );
        gsap.fromTo(
          copy,
          {
            alpha: 0,
          },
          {
            alpha: 1,
            duration: 1.4,
            ease: 'power2.out',
            delay: 0.9,
          }
        );
        gsap.fromTo(
          items,
          {
            alpha: 0,
            y: 50,
          },
          {
            alpha: 1,
            y: 0,
            duration: 0.6,
            ease: 'power2.out',
            delay: 1.2,
            stagger: 0.3,
            onComplete: this.maybeDisconnect,
          }
        );
      } else {
        gsap.set( [heading, copy, items], { opacity: 0 } );
      }
    },
    maybeDisconnect() {
      if ( ! this.replay ) {
        this.intersectionObserver.disconnect();
      }
      this.isAnimating = false;
    },
  },
};
</script>
