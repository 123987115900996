<template>
  <component
    :is="tag"
    v-if="isAudience"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    audience: {
      type: [Array, String],
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    isAudience() {
      return this.audience.includes(this.$root.audience)
    },
  },
}
</script>
