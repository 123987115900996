<script>
export default {
  props: {
    dispatch: {
      type: Boolean,
      default: true,
    },
    callback: {
      type: Function,
      default: () => ({}),
    },
  },

  data() {
    return {
      width: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', this.setWidth)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setWidth)
  },

  methods: {
    setWidth() {
      this.width = window.innerWidth
      this.callback(this.width)
    },
  },

  render(createElement) {
    return createElement(this.$attrs.tag || 'div',
      this.dispatch ? this.$scopedSlots.default({ width: this.width }) : this.$slots.default,
    )
  }
}
</script>
