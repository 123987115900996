<script>
import accordion from '../util/accordion'

import BaseAccordionTransition from '../components/BaseAccordionTransition.vue'

export default {
  inject: { accordion },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    id: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: 'show',
      validator: value => [
        'if',
        'show',
      ].includes(value),
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
      // expanded: this.isCurrentHash || false,
    }
  },
  computed: {
    button() {
      return this.accordion.buttons.find(this.isLabelledbyButton)
    },
    role() {
      const accordionVm = this.accordion

      if (accordionVm.multiple && accordionVm.panels.length > 6) {
        return null
      }

      return 'region'
    },
    ariaLabelledby() {
      return this.button ? this.button.id : null
    },
    // isCurrentHash() {
    //   const windowHash = window.location.hash.substring(1)

    //   return this.id === `base-accordion-${windowHash}`
    // },
  },
  created() {
    this.$on('toggle', this.toggle)

    this.accordion.$emit('create:panel', this)
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
    },
    handleKeydown(event) {
      if (!event.ctrlKey) {
        return
      }

      const eventToEmit = {
        PageUp: 'focus:header.current',
        PageDown: 'focus:header.next',
      }[event.key]

      if (!eventToEmit) {
        return
      }

      event.preventDefault()

      this.accordion.$emit(eventToEmit)
    },
    isLabelledbyButton({ controls }) {
      return this.id === controls
    },
    createChild(h, data) {
      if (!(this.mode === 'show' || this.expanded)) {
        return undefined
      }
      return h(BaseAccordionTransition, [h(this.tag, data, this.$slots.default)])
    },
  },
  render(h) {
    const {
      id,
      role,
      expanded,
      ariaLabelledby,
    } = this
    const data = {
      attrs: {
        id,
        role,
        'aria-labelledby': ariaLabelledby,
      },
      class: { expanded },
      on: { keydown: this.handleKeydown },
    }

    if (this.mode === 'show') {
      data.directives = [{
        name: 'show',
        value: expanded,
      }]
    }

    return this.createChild(h, data)
  },
}
</script>
