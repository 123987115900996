<template>
  <nav class="menu-primary">
    <slot />
    <transition name="slide-menu">
      <slot
        v-if="showMenu"
        name="menu"
      />
    </transition>
    <button
      v-if="screenSmall"
      class="menu-primary__toggle"
      :class="`menu-primary__toggle--${toggleClass}`"
      @click="toggleMenu"
    >
      <span class="menu-primary__icon menu-primary__icon--top" />
      <span class="menu-primary__icon menu-primary__icon--bottom" />
    </button>
  </nav>
</template>

<script>
export default {
  props: {
    windowWidth: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      menuActive: false,
    }
  },
  computed: {
    screenSmall() {
      return this.windowWidth < 1024
    },
    showMenu() {
      return !this.screenSmall || this.menuActive
    },
    toggleClass() {
      return this.menuActive ? 'open' : 'closed'
    },
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive
    },
  },
}
</script>
