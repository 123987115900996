<template>
  <div
    class="step-disclosure"
    :class="{ 'step-disclosure--active' : isActive }"
  >
    <transition name="fade-disclosure">
      <div
        v-if="isActive"
        class="step-disclosure__panel"
      >
        <slot />
      </div>
    </transition>
    <button
      class="step-disclosure__button"
      @click="toggleDisclosure"
    >
      {{ isActive ? 'Close' : 'Learn More +' }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggleDisclosure() {
      this.isActive = !this.isActive
    },
  },
}
</script>
